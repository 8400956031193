export default {
  siteTitle: 'Get bonus',
  blogerName: 'WEEDZAO',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@WEEDZERATV'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/weedzaotips/'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/weedzao/'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1gocasino.life/cfa3a9da9',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://monrocasino.life/c17e85c2b',
      gameTitle: 'Burning Chilli X',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c1b025e4e',
      gameTitle: 'Alien Fruits',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>'
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/cb95735d6',
      gameTitle: 'STARDA QUEEN',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/cd33c329f',
      gameTitle: 'LEGZO PUNK',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c9162e6e2',
      gameTitle: 'IZZI ART',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/cf0b399e0',
      gameTitle: 'Doors Of Fresh',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c2a56eda0',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/cd652711a',
      gameTitle: 'Jet Air',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>WEEDZAO</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'WEEDZAO',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
